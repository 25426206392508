html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 62.5%;
  background-color: #111;
  color: #fff;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Mono', source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: yellow;
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
