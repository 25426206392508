.CopyButton {
  appearance: none;
  border: 0;
  background: transparent;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.3rem 0.7rem;
  color: #999;
  background: #111;
  border: 1px solid #666;
  border-radius: 0.2rem;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.CopyButton:hover {
  border-color: #999;
  color: #fff;
}
