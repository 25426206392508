.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #111;
  color: #fff;
  padding: 0 0 0 1.5rem;
  border-bottom: 1px solid #222;
}

.App-header h1 {
  font-size: 1.8rem;
  font-weight: 500;
}

.App-header button {
  appearance: none;
  border: 0;
  background: transparent;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  padding: 1.5rem 1.5rem;
  color: #111;
  background-color: #24d61c;
  /*border: 1px solid #fff;*/
  /*border-radius: 0.2rem;*/
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.App-header button:disabled {
  background-color: #666;
  color: #111;
}

.App-main {
  flex: 1;
  display: flex;
  background-color: #111;
  width: 100%;
}

.App-main__textarea {
  position: relative;
  flex: 1;
}

.App-main__textarea + .App-main__textarea {
  border-left: 1px solid #222;
}

.App-main__textarea textarea {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  appearance: none;
  border: 0;
  font-family: 'DM Mono', source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 1.6rem;
  background-color: #111;
  color: #fff;
  padding: 1.5rem;
  resize: none;
}

.App-main__copy-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.App-footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-top: 1px solid #222;
}

.App-footer p {
  font-size: 1.4rem;
  margin: 0;
}
